import React from 'react'
import { TeamSets } from '../TeamSets'
import { StyledProps } from '../../../TenantDisplay/types'
import { IMatchResult, ITeam } from '../../types'
import TeamPlayer from '../TeamPlayer'
import * as S from './Team.styles'

interface TeamProps extends StyledProps {
  team: ITeam
  isFinished: boolean
  sets: IMatchResult[]
  maxPlayers: number
  visiblePadelsCount: number
  hideGuests: boolean
}

function Team({ className, team, isFinished, sets, maxPlayers, visiblePadelsCount, hideGuests }: TeamProps) {
  const players = []

  for (let i = 0; i < maxPlayers; i++) {
    players.push(i)
  }

  return (
    <S.Wrapper className={className}>
      <S.TeamPlayers>
        {team.team_id === '0' && !hideGuests && <S.TeamDivider isFinished={isFinished} />}
        {players.map(index => {
          if (hideGuests && !team.players[index]) {
            return null
          }

          return (
            <TeamPlayer
              isFinished={isFinished}
              key={index}
              player={team.players[index]}
              won={team.team_result === 'WON'}
              visiblePadelsCount={visiblePadelsCount}
              hideGuests={hideGuests}
            />
          )
        })}
      </S.TeamPlayers>
      {isFinished && <TeamSets teamId={team.team_id} sets={sets} />}
    </S.Wrapper>
  )
}

export default Team
export { Team }
