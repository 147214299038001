import styled from 'styled-components'
import { TeamSet as BaseTeamSet } from '../TeamSet'
import { fluidSize } from '../../../../utils/fluidSize'

const TeamSet = styled(BaseTeamSet)``

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  min-height: ${props => fluidSize('100px', props.theme.scale)};
`

export { TeamSet, Wrapper }
