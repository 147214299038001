import React from 'react'
import { StyledProps } from '../../../TenantDisplay/types'
import { IMatchResult } from '../../types'
import * as S from './TeamSet.styles'

interface TeamSetProps extends StyledProps {
  teamId: string
  set: IMatchResult
}

function TeamSet({ teamId, set }: TeamSetProps) {
  const maxScore = Math.max(...set.scores.map(score => score.score))
  const score = set.scores.filter(scores => scores.team_id === teamId).pop()?.score || 0

  return <S.Score isMax={score > 0 && score === maxScore}>{score}</S.Score>
}

export default TeamSet
export { TeamSet }
