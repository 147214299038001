import styled from 'styled-components'
import { Now as BaseNow } from '../Now'
import { Timeslots as BaseTimeslots } from '../Timeslots'
import { Match as BaseMatch } from '../Match'
import { StyledProps } from '../../../TenantDisplay/types'
import { fluidSize } from '../../../../utils/fluidSize'

const TimeColumn = styled.div`
  box-sizing: border-box;
  flex: 0 0 ${fluidSize('71px')};
  max-width: ${fluidSize('71px')};

  & > div.timeslot-group {
    border: 1px solid transparent;
    border-right-width: 0;
  }
`

const DayColumn = styled.div<StyledProps>`
  position: relative;
  flex: 1;

  & > div.timeslot-group {
    border: 1px solid ${props => props.theme.colors.timeslotBorderColor};
    border-bottom-color: ${props => props.theme.colors.backgroundColor};
    border-right-width: 0;
  }
`

const TenantResources = styled.div<StyledProps>`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -1px;
  left: ${fluidSize('72px')};
  --widthMargin: ${fluidSize('98px')};
  width: calc(100% - var(--widthMargin));
  padding: ${fluidSize('11px')} 0 ${fluidSize('10px')};
  background: ${props => props.theme.colors.backgroundColor};
  z-index: 5;
`

const TenantResource = styled.div<StyledProps>`
  flex: 1;
  font-size: ${fluidSize('20px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: ${fluidSize('40px')};
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.colors.textColor};
`

const Matches = styled.div<StyledProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`

const TimeGrid = styled.div<StyledProps>`
  padding-top: ${fluidSize('62px')};
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: overlay;
  overflow: scroll;
  height: 100%;
  border-right: 1px solid ${props => props.theme.colors.timeslotBorderColor};
  margin-right: ${fluidSize('3px')};
`

const Wrapper = styled.div`
  position: relative;
  height: 89%;
`

const Match = styled(BaseMatch)``
const Now = styled(BaseNow)``
const Timeslots = styled(BaseTimeslots)``

export {
  DayColumn,
  Match,
  Matches,
  Now,
  TenantResource,
  TenantResources,
  TimeColumn,
  TimeGrid,
  Timeslots,
  Wrapper,
}
