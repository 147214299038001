export const ROUTER_BASENAME = process.env.REACT_APP_ROUTER_BASENAME

export const DATA_RELOAD_INTERVAL = Number(process.env.REACT_APP_DATA_RELOAD_INTERVAL)

export const CURRENT_TIME_INTERVAL = Number(process.env.REACT_APP_CURRENT_TIME_REFRESH_INTERVAL)

export const PADELS_COLUMN_COUNT = Number(process.env.REACT_APP_PADELS_COLUMN_COUNT)

export const PADELS_ROTATE_INTERVAL = Number(process.env.REACT_APP_PADELS_ROTATE_INTERVAL)

export const TIMESLOTS_START_TIME = process.env.REACT_APP_TIMESLOTS_START_TIME

export const TIMESLOTS_END_TIME = process.env.REACT_APP_TIMESLOTS_END_TIME

export const TIMESLOTS_VISIBLE_RANGE_FROM = Number(process.env.REACT_APP_TIMESLOTS_VISIBLE_RANGE_FROM)

export const TIMESLOTS_VISIBLE_RANGE_TO = Number(process.env.REACT_APP_TIMESLOTS_VISIBLE_RANGE_TO)

export const TIMESLOT_LABEL_MARGIN = Number(process.env.REACT_APP_TIMESLOT_LABEL_MARGIN)

// element height of 1-hour timeslot group
export const TIMESLOT_GROUP_HEIGHT = Number(process.env.REACT_APP_TIMESLOT_GROUP_HEIGHT)

export const TIMESLOT_STEP = Number(process.env.REACT_APP_TIMESLOT_STEP)

export const GOTOCOURT_RANGE_FROM = Number(process.env.REACT_APP_GOTOCOURT_RANGE_FROM)

export const GOTOCOURT_RANGE_TO = Number(process.env.REACT_APP_GOTOCOURT_RANGE_TO)
