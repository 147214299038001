import styled from 'styled-components'
import { ReactComponent as PlaytomicLogo } from '@playtomic/display/assets/playtomic.svg'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Logo = styled(PlaytomicLogo)`
  max-width: 400px;
  width: 100%;
`

export { Logo, Wrapper }
