import moment from 'moment-timezone'
import React, { useContext, useMemo } from 'react'
import TennisBallIcon from '../../../../assets/tennisball.svg'
import { Team } from '../Team'
import { StyledProps } from '../../../TenantDisplay/types'
import { IMatch, ITeam } from '../../types'
import { TIMESLOTS_START_TIME, GOTOCOURT_RANGE_FROM, GOTOCOURT_RANGE_TO } from '../../../../config/constants'
import { getPixelsByTime, pixelInterpolation } from '../../../../utils/timeslots'
import { MatchesContext } from '../Matches'
import { useDimensions, useSearchParams, useWindowSize } from '../../../../hooks'
import * as S from './Match.styles'

interface MatchProps extends StyledProps {
  match: IMatch
  visiblePadelsCount: number
}

function Match({ className, match, visiblePadelsCount }: MatchProps) {
  const { currentTime, timezone } = useContext(MatchesContext)
  const searchParams = useSearchParams()
  const startDate = match.start_date
  const endDate = match.end_date

  const hidePast = searchParams.has('hide_past') ? Boolean(searchParams.get('hide_past')) : false
  const hideGuestsOnePlayer = searchParams.has('hide_guests')
    ? Boolean(searchParams.get('hide_guests'))
    : false

  const start = useMemo(() => moment(`${startDate}Z`).tz(timezone), [startDate, timezone])
  const end = useMemo(() => moment(`${endDate}Z`).tz(timezone), [endDate, timezone])
  const current = useMemo(() => moment.tz(`${start.format('YYYY-MM-DD')} ${currentTime}:00`, timezone), [
    start,
    currentTime,
    timezone,
  ])

  const [isPlaying, isFinished, showGoToCourt] = useMemo(() => {
    const currentEnd = moment.tz(`${moment().format('YYYY-MM-DD')} ${currentTime}:00`, timezone)
    const showGoToCourt = (): boolean =>
      current.isBetween(
        start.clone().add(GOTOCOURT_RANGE_FROM, 'minutes'),
        start.clone().add(GOTOCOURT_RANGE_TO, 'minutes'),
        undefined,
        '[]',
      )

    return [
      start.isSameOrBefore(currentEnd) && end.isAfter(currentEnd),
      end.isSameOrBefore(currentEnd),
      showGoToCourt(),
    ]
  }, [start, end, current, currentTime, timezone])

  const windowSize = useWindowSize()

  const top = useMemo(
    () =>
      getPixelsByTime(
        start.diff(
          moment.tz(`${start.format('YYYY-MM-DD')}T${TIMESLOTS_START_TIME}:00`, timezone),
          'minutes',
        ),
      ),
    // eslint-disable-next-line
    [start, timezone, windowSize],
  )
  const height = useMemo(
    () => getPixelsByTime(end.diff(start, 'minutes')) - 2 * pixelInterpolation(2), // margin of PadelMatch
    // eslint-disable-next-line
    [start, end, windowSize],
  )
  let statusOpacity = '0'

  if (isFinished) {
    statusOpacity = '0.7'
  }
  if (isPlaying) {
    statusOpacity = '0.1'
  }

  const [teamsRef, teamsDimensions] = useDimensions<HTMLDivElement>()
  const teamsHeight = teamsDimensions ? teamsDimensions.height : height
  const marginTop = useMemo(
    () => Math.min((height - teamsHeight) / 2, pixelInterpolation(48)),
    // eslint-disable-next-line
    [height, teamsHeight, windowSize],
  )

  const tennisBallTop = useMemo(
    () => (isPlaying ? getPixelsByTime(current.diff(start, 'minutes')) : 0),
    // eslint-disable-next-line
    [start, current, isPlaying, windowSize],
  )
  const maxPlayers =
    match.sport_id === 'PADEL' ? 2 : Math.max(...match.teams.map(team => team.players.length))
  const playersNum = match.teams
    .map(team => team.players.map(player => Number(Boolean(player))).reduce((prev, cur) => prev + cur, 0))
    .reduce((prev, cur) => prev + cur, 0)

  const hideTeams = isFinished && hidePast
  const hideGuests = hideGuestsOnePlayer && playersNum === 1

  return (
    <S.Wrapper className={className} style={{ height, top }}>
      <S.MatchStatusOverlay opacity={statusOpacity} />
      {isPlaying && (
        <S.TennisBall style={{ ['--top' as any]: `${tennisBallTop}px` }}>
          <S.TennisBallImg src={TennisBallIcon} alt="Playing" />
        </S.TennisBall>
      )}
      {!hideTeams && (
        <S.Teams ref={teamsRef} style={{ marginTop }}>
          {showGoToCourt && <S.GoToCourt>Go To Court</S.GoToCourt>}
          {match.teams.map((team: ITeam) => (
            <Team
              key={team.team_id}
              maxPlayers={maxPlayers}
              team={team}
              isFinished={isFinished}
              sets={match.results}
              visiblePadelsCount={visiblePadelsCount}
              hideGuests={hideGuests}
            />
          ))}
          {isFinished && match.teams[0].team_result === null && (
            <S.TeamsUploadScore>Upload Score</S.TeamsUploadScore>
          )}
        </S.Teams>
      )}
    </S.Wrapper>
  )
}

export default Match
export { Match }
