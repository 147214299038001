import React from 'react'
import Trophy from '../../../../assets/trophy.svg'
import { StyledProps } from '../../../TenantDisplay/types'
import * as S from './Header.styles'

type HeaderProps = StyledProps

function Header({ className }: HeaderProps) {
  return (
    <S.Header className={className}>
      <S.TrophyImg src={Trophy} alt="Top Players" />
      Top
    </S.Header>
  )
}

export default Header
export { Header }
