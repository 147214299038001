import React from 'react'
import LogoIcon from '@playtomic/display/assets/playtomic.svg'
import { StyledProps } from '../../types'
import * as S from './Logo.styles'

function Logo({ className }: StyledProps) {
  return (
    <S.Wrapper className={className}>
      <S.LogoImg src={LogoIcon} alt="Playtomic Icon" />
    </S.Wrapper>
  )
}

export default Logo
export { Logo }
