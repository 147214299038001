import React from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { Layout } from '../Layout'
import { useSearchParams } from '../../../../hooks'
import { SportId } from '../../../Matches/types'

interface TenantDisplayParams {
  tenantId?: string
  date?: string
}

function TenantDisplay() {
  const params = useSearchParams()
  const sportId = params.get('sports')

  const { tenantId, date } = useParams<TenantDisplayParams>()

  const fromStartDate = moment(date || moment(), 'YYYY-MM-DD')
    .startOf('day')
    .format('YYYY-MM-DDTHH:mm:ss')
  const toStartDate = moment(date || moment(), 'YYYY-MM-DD')
    .endOf('day')
    .format('YYYY-MM-DDTHH:mm:ss')

  if (!tenantId) {
    return null
  }

  return (
    <Layout
      tenantId={tenantId}
      fromStartDate={fromStartDate}
      toStartDate={toStartDate}
      sportId={sportId ? (sportId.toUpperCase() as SportId) : null}
    />
  )
}

export default TenantDisplay
export { TenantDisplay }
