import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle<any>`
  body {
    background-color: ${props => props.theme.colors.backgroundColor};
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: #ffffff55 auto;
  }

  *::-webkit-scrollbar {
    width: 7px;
    border-radius: 20px;
  }

  *::-webkit-scrollbar-track, *::-webkit-scrollbar-corner {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffffff55;
    border-radius: 20px;
  }
`
