import { useRef, useState, useLayoutEffect, MutableRefObject } from 'react'
import { useWindowSize } from './useWindowSize'

const useDimensions = <T extends HTMLElement>(): [MutableRefObject<T | null>, DOMRect | null] => {
  const ref = useRef<T>(null)
  const [dimensions, setDimensions] = useState<DOMRect | null>(null)
  const windowSize = useWindowSize()

  useLayoutEffect(() => {
    if (ref.current) {
      setDimensions(ref.current.getBoundingClientRect().toJSON())
    }
  }, [windowSize])

  return [ref, dimensions]
}

export { useDimensions }
