import styled from 'styled-components'
import { fluidSize } from '../../../../utils/fluidSize'

const TrophyImg = styled.img`
  width: ${fluidSize('28px')};
  height: ${fluidSize('28px')};
  margin-right: ${fluidSize('15px')};
  vertical-align: middle;
`

const Header = styled.header`
  background-color: ${props => props.theme.colors.darkColor};
  color: ${props => props.theme.colors.textColor};
  padding: ${fluidSize('8px')};
  font-size: ${fluidSize('24px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: ${fluidSize('40px')};
  letter-spacing: normal;
  text-align: center;
`

export { Header, TrophyImg }
