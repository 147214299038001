import React from 'react'
import { StyledProps } from '../../../TenantDisplay/types'
import { IMatchResult } from '../../types'
import { TeamDivider } from '../Team/Team.styles'
import * as S from './TeamSets.styles'

interface IProps extends StyledProps {
  teamId: string
  sets: IMatchResult[]
}

function TeamSets({ className, teamId, sets }: IProps) {
  return (
    <S.Wrapper className={className}>
      {teamId === '0' && <TeamDivider />}
      {sets.map((set, index) => (
        <S.TeamSet key={index} teamId={teamId} set={set} />
      ))}
    </S.Wrapper>
  )
}

export default TeamSets
export { TeamSets }
