import React, { useState, useEffect, useMemo } from 'react'
import AnonymousAvatar from '../../../../assets/anonymous.svg'
import { StyledProps } from '../../../TenantDisplay/types'
import { pictureColours } from './constants'
import * as S from './UserPicture.styles'

interface UserPictureProps extends StyledProps {
  picture: string | null
  name: string
  anonymous?: boolean
  borderless?: boolean
  size?: number
}

let pictureColourIndex = -1

function UserPicture({ className, anonymous, picture, name }: UserPictureProps) {
  const [colourIndex, setColourIndex] = useState(0)
  const initials = useMemo(
    () =>
      name
        .split(' ')
        .map((word: string) => word[0]?.toUpperCase())
        .slice(0, 2)
        .join(''),
    [name],
  )

  useEffect(() => {
    if (!picture) {
      pictureColourIndex = pictureColourIndex + 1
      if (pictureColourIndex > 9) {
        pictureColourIndex = 0
      }

      setColourIndex(pictureColourIndex)
    }
  }, [picture])

  const computedPicture = useMemo(() => {
    const params = picture?.match(/\/upload\/([^/]+)\//)?.[1]

    if (params) {
      return picture!.replace(params, 'c_limit,w_96,f_auto,q_auto')
    }
    return picture
  }, [picture])

  return (
    <S.Wrapper className={className} background={pictureColours[colourIndex]}>
      {!anonymous && (
        <>
          {computedPicture && <S.Picture src={computedPicture} alt={name} />}
          {!picture && initials}
        </>
      )}
      {anonymous && <S.Picture anonymous={true} src={AnonymousAvatar} alt={name} />}
    </S.Wrapper>
  )
}

export default UserPicture
export { UserPicture }
