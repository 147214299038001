import React from 'react'
import { StyledProps } from '@playtomic/display/utils/types'
import { IUser } from '../../../Users/interfaces'
import * as S from './PlayersList.styles'

interface PlayersListProps extends StyledProps {
  players: IUser[]
  isPlayersLoading: boolean
  isUsersLoading: boolean
}

function PlayersList({ className, players, isUsersLoading, isPlayersLoading }: PlayersListProps) {
  return (
    <S.Wrapper className={className}>
      <S.Spinner loading={isPlayersLoading || isUsersLoading}>
        {players.map((player, index) => (
          <S.PlayersListItem key={index} position={index + 1} player={player} />
        ))}
      </S.Spinner>
    </S.Wrapper>
  )
}

export default PlayersList
export { PlayersList }
