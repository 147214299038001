import { IByMatchId, IHasByMatchId } from '../views/Matches/types'

const merge = (state: IByMatchId<any>, newState: IByMatchId<any>) => ({
  ...state,
  ...newState,
})

const mergeByMatchId = <T extends IHasByMatchId>(state: T, action: any, name: string): T => {
  const items = action && action.meta && action.meta[name]

  if (!items || items.length === 0) return state

  return {
    ...state,
    byMatchId: merge(state.byMatchId, items),
  }
}

export default mergeByMatchId
export { mergeByMatchId }
