import styled from 'styled-components'
import { fluidSize } from '../../../../utils'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
`

const Column = styled.div<{ flex?: string }>`
  flex: ${props => (props.flex ? `0 0 ${fluidSize(props.flex)}` : 'auto')};
`

export { Column, Wrapper }
