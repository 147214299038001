import { ActionType, getType } from 'typesafe-actions'

import { IByMatchId, IMatch } from '../types'
import * as actions from './actions'
import mergeByMatchId from '../../../utils/mergeByMatchId'

export interface IMatchesState {
  byMatchId: IByMatchId<IMatch>
  matchIds: string[]
  isLoading: boolean
  error?: string
}

export type IMatchesAction = ActionType<typeof actions>

const initialState: IMatchesState = {
  byMatchId: {},
  matchIds: [],
  isLoading: false,
}

const matches = (unmergedState = initialState, action: IMatchesAction): IMatchesState => {
  const state = mergeByMatchId(unmergedState, action, 'matches')

  switch (action.type) {
    case getType(actions.fetchDayMatchesPending):
      return {
        ...state,
        isLoading: true,
      }

    case getType(actions.fetchDayMatchesSuccess):
      return {
        ...state,
        isLoading: false,
        matchIds: action.payload,
      }

    case getType(actions.fetchDayMatchesFailure):
      return {
        ...state,
        isLoading: false,
        matchIds: [],
        error: action.payload,
      }

    default:
      return state
  }
}

export default matches
