import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import to from 'await-to-js'
import axios from 'axios'
import { normalize } from 'normalizr'

import { fetchDayMatchesFailure, fetchDayMatchesPending, fetchDayMatchesSuccess } from './actions'
import { IMatch, INormalizedMatchIds, SportId } from '../types'
import { endpoint } from '../../../config/api'
import { matchesSchema } from '../schemas'
import { StoreState } from '../../../store'
import { IActions } from '../../../store/actions'
import { fetchUsers } from '../../Users/store/thunks'
import getUserIdsFromMatches from '../../../utils/getUserIdsFromMatches'

export const fetchDayMatches = (
  tenantId: string,
  fromStartDate: string,
  toStartDate: string,
  sportId?: SportId | null,
): ThunkAction<void, StoreState, unknown, IActions> => async (
  dispatch: ThunkDispatch<StoreState, unknown, IActions>,
) => {
  dispatch(fetchDayMatchesPending())

  const [err, response] = await to(
    axios.get<IMatch[]>(`${endpoint}/v1/matches`, {
      params: {
        tenant_id: tenantId,
        from_start_date: fromStartDate,
        to_start_date: toStartDate,
        match_status: 'PENDING,IN_PROGRESS,PLAYED,VALIDATING,CONFIRMED,REJECTED,EXPIRED',
        sport_id: sportId,
        size: 100,
      },
    }),
  )

  if (response) {
    const normalizedData = normalize<any, INormalizedMatchIds, string[]>(response.data, matchesSchema)

    await to(
      dispatch(
        fetchUsers(
          getUserIdsFromMatches(
            normalizedData.result.map((matchId: string) => normalizedData.entities.matches![matchId]),
          ),
        ),
      ),
    )
    dispatch(fetchDayMatchesSuccess(normalizedData.result, normalizedData.entities))
    return undefined
  }

  if (err) {
    dispatch(fetchDayMatchesFailure(err.message))
  }

  return undefined
}
