import { createAction } from 'typesafe-actions'

import { INormalizedUserIds } from '../interfaces'

export const fetchTop10PlayersPending = createAction('top10/FETCH_TOP10_PLAYERS_PENDING')<void>()
export const fetchTop10PlayersSuccess = createAction('top10/FETCH_TOP10_PLAYERS_SUCCESS')<
  string[],
  INormalizedUserIds
>()
export const fetchTop10PlayersFailure = createAction('top10/FETCH_TOP10_PLAYERS_FAILURE')<string>()
