import React from 'react'
import * as S from './Home.styles'

function Home() {
  return (
    <S.Wrapper>
      <S.Logo />
    </S.Wrapper>
  )
}

export { Home }
