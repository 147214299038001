export const fluidSize = (minSize: string, scale?: number) => {
  const viewportMin = '1080px'
  const viewportMax = '2019px'
  const units = 'px'
  const toNumber = (value: string, units: string) => Number(value.replace(units, ''))
  const maxSize = null

  const minimumSize = toNumber(minSize, units) * (scale || 1)
  const maximumSize = Math.round(
    (maxSize ? toNumber(maxSize, units) : toNumber(minSize, units) * 1.869) * (scale || 1),
  )
  const viewportDifference = toNumber(viewportMax, units) - toNumber(viewportMin, units)
  const sizeDifference = maximumSize - minimumSize

  return `calc(${minimumSize}${units} + ${sizeDifference} * ((100vh - ${viewportMin}) / ${viewportDifference}));`
}

export const interpolateDueResourcesCount = (visibleResourcesCount: number) => visibleResourcesCount / 4
