import { createSelector } from 'reselect'
import { StoreState } from './index'
import { IMatch, SportId } from '../views/Matches/types'
import { ITenant, IResource } from '../views/Tenant/interfaces'

export const getTop10PlayersIds = (state: StoreState) => state.top10Players.userIds

export const isTop10PlayersLoading = (state: StoreState) => state.top10Players.isLoading

export const getUsersByIds = (state: StoreState) => state.users.byUserId

export const isUsersLoading = (state: StoreState) => state.users.isLoading

export const getTop10Players = createSelector(getTop10PlayersIds, getUsersByIds, (playersIds, users) =>
  playersIds.map(id => users[id]),
)

export const getMatchesIds = (state: StoreState) => state.matches.matchIds

export const getMatchesByIds = (state: StoreState) => state.matches.byMatchId

export const isMatchesLoading = (state: StoreState) => state.matches.isLoading

export const isTenantLoading = (state: StoreState) => state.tenant.isLoading

export const getTenant = (state: StoreState) => state.tenant.data

export const getMatches = createSelector(getMatchesIds, getMatchesByIds, (matchesIds, matchesByIds) =>
  matchesIds.map((id: string) => matchesByIds[id]),
)

export const getTenantResourcesBySport = (sportId: SportId | null) =>
  createSelector(getTenant, (tenant: ITenant | null) =>
    tenant
      ? tenant.resources.filter((resource: IResource) => (sportId ? resource.sport_id === sportId : true))
      : [],
  )

export const getMatchesGroupedByTenantResourcesAndSport = (sportId: SportId | null) =>
  createSelector(getTenantResourcesBySport(sportId), getMatches, (tenantResources, matches) => {
    const record: Record<string, IMatch[]> = {}

    tenantResources.forEach(tenantResource => {
      record[tenantResource.resource_id] = matches.filter(
        (match: IMatch) => match.resource_id === tenantResource.resource_id,
      )
    })

    return record
  })
