import styled from 'styled-components'
import { Spinner as BaseSpinner } from '@playtomic/display/views/TenantDisplay/components'
import { Header as BaseHeader } from '../Header'
import { MatchesGrid as BaseMatchesGrid } from '../MatchesGrid'

const Header = styled(BaseHeader)``
const MatchesGrid = styled(BaseMatchesGrid)``
const Spinner = styled(BaseSpinner)``
const Wrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.backgroundColor};
  height: 100%;
`

export { Header, MatchesGrid, Spinner, Wrapper }
