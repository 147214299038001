import styled from 'styled-components'
import { StyledProps } from '../../../TenantDisplay/types'
import { fluidSize } from '../../../../utils/fluidSize'

interface ScoreProps extends StyledProps {
  isMax: boolean
}

const Score = styled.div<ScoreProps>`
  flex: auto;
  font-size: ${props => fluidSize('36px', props.theme.scale)};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: ${props => fluidSize('30px', props.theme.scale)};
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.colors.invertedTextColor};
  opacity: ${props => (props.isMax ? '1' : '0.4')};
`

export { Score }
