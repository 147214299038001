import { ActionType, getType } from 'typesafe-actions'

import * as actions from './actions'

export interface ITop10State {
  // byUserId: IByUserId<ITop10Player>
  userIds: string[]
  isLoading: boolean
  error?: string
}

export type ITop10Action = ActionType<typeof actions>

const initialState: ITop10State = {
  userIds: [],
  isLoading: false,
}

const top10Players = (state = initialState, action: ITop10Action): ITop10State => {
  switch (action.type) {
    case getType(actions.fetchTop10PlayersPending):
      return {
        ...state,
        isLoading: true,
      }

    case getType(actions.fetchTop10PlayersSuccess):
      return {
        ...state,
        isLoading: false,
        userIds: action.payload,
      }

    case getType(actions.fetchTop10PlayersFailure):
      return {
        ...state,
        isLoading: false,
        userIds: [],
        error: action.payload,
      }

    default:
      return state
  }
}

export default top10Players
