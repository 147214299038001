import React, { ReactNode, useContext } from 'react'
import { BeatLoader } from 'react-spinners'
import { ThemeContext } from 'styled-components'
import { Theme } from '../../types'
import * as S from './Spinner.styles'

interface SpinnerProps {
  loading: boolean
  children: ReactNode
}

function Spinner({ loading, children }: SpinnerProps) {
  const theme = useContext<Theme>(ThemeContext)

  return (
    <>
      {loading && (
        <S.Loader>
          <BeatLoader color={theme.colors.textColor} />
        </S.Loader>
      )}
      {children}
    </>
  )
}

export default Spinner
export { Spinner }
