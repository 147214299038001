import to from 'await-to-js'
import axios from 'axios'
import { normalize } from 'normalizr'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { fetchTop10PlayersFailure, fetchTop10PlayersPending, fetchTop10PlayersSuccess } from './actions'
import { endpoint } from '../../../config/api'
import { INormalizedUserIds, ITop10Player, IRanking } from '../interfaces'
import { top10PlayersSchema, ranksSchema } from '../schemas'
import { StoreState } from '../../../store'
import { IActions } from '../../../store/actions'
import { fetchUsers } from '../../Users/store/thunks'
import { SportId } from '../../Matches/types'

export const fetchRankings = (tenantId: string) => async (
  dispatch: ThunkDispatch<StoreState, unknown, IActions>,
) => {
  const [err, response] = await to(
    axios.get<IRanking[]>(`${endpoint}/v1/rankings`, {
      params: {
        tenant_id: tenantId,
      },
    }),
  )

  if (response && response.data && response.data.length > 0) {
    // FIXME: use first result?
    const ranking = response.data[0]

    const [err, ranksResponse] = await to(
      axios.get(`${endpoint}/v1/ranking_ranks`, {
        params: {
          ranking_id: ranking.ranking_id,
        },
      }),
    )

    if (ranksResponse) {
      const normalizedData = normalize<any, INormalizedUserIds, string[]>(ranksResponse.data, ranksSchema)

      await to(dispatch(fetchUsers(normalizedData.result)))
      dispatch(fetchTop10PlayersSuccess(normalizedData.result, normalizedData.entities))

      return true
    }

    if (err) {
      dispatch(fetchTop10PlayersFailure(err.message))
    }
  }

  if (err) {
    dispatch(fetchTop10PlayersFailure(err.message))
  }

  return false
}

export const fetchTop10Players = (
  tenantId: string,
  fromStartDate: string,
  sportId: SportId | null,
): ThunkAction<Promise<string[] | undefined>, StoreState, unknown, IActions> => async (
  dispatch: ThunkDispatch<StoreState, unknown, IActions>,
) => {
  dispatch(fetchTop10PlayersPending())

  const haveRankings = await dispatch(fetchRankings(tenantId))

  if (!haveRankings) {
    const [err, response] = await to(
      axios.get<ITop10Player[]>(`${endpoint}/v1/matches_summary/top_players_by_tenant/last_month`, {
        params: {
          tenant_id: tenantId,
          sport_id: sportId,
        },
      }),
    )

    if (response) {
      const normalizedData = normalize<any, INormalizedUserIds, string[]>(response.data, top10PlayersSchema)

      await to(dispatch(fetchUsers(normalizedData.result)))
      dispatch(fetchTop10PlayersSuccess(normalizedData.result, normalizedData.entities))

      return normalizedData.result
    }

    if (err) {
      dispatch(fetchTop10PlayersFailure(err.message))
    }
  }

  return undefined
}
