import styled from 'styled-components'
import { StyledProps } from '../../../TenantDisplay/types'
import { fluidSize } from '../../../../utils/fluidSize'

const PlayerPosition = styled.div<StyledProps>`
  opacity: 0.5;
  color: ${props => props.theme.colors.textColor};
  font-size: ${fluidSize('13px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: ${fluidSize('15px')};
  margin-right: ${fluidSize('5px')};
  text-align: right;
  flex: 0 0 ${fluidSize('14px')};
`

const PlayerName = styled.div<StyledProps>`
  color: ${props => props.theme.colors.textColor};
  font-size: ${fluidSize('15px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: ${fluidSize('18px')};
  letter-spacing: normal;
  margin-left: ${fluidSize('5px')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: ${fluidSize('128px')};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${fluidSize('8px')};

  &:nth-child(odd) {
    background-color: ${props => props.theme.colors.lightColor};
  }
`

export { PlayerName, PlayerPosition, Wrapper }
