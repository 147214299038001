import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ROUTER_BASENAME } from './config/constants'
import { ThemeProvider } from './components'
import store from './store'
import { Home } from './views/Home'
import { TenantDisplay } from './views/TenantDisplay'

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <Router basename={ROUTER_BASENAME}>
          <Switch>
            <Route path={'/:tenantId/:date?'}>
              <TenantDisplay />
            </Route>
            <Route path="*">
              <Home />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  )
}

export default App
