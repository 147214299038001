import React, { useContext } from 'react'
import { MatchesContext } from '../Matches'
import { ITenant } from '../../../Tenant/interfaces'
import * as S from './Header.styles'

interface HeaderProps {
  tenant: ITenant
}

function Header({ tenant }: HeaderProps) {
  const { currentTime } = useContext(MatchesContext)

  return (
    <S.Wrapper>
      <S.CurrentTime>{currentTime}</S.CurrentTime>
      Playtomic Display: {tenant.tenant_name}
    </S.Wrapper>
  )
}

export default Header
export { Header }
