import styled from 'styled-components'
import { fluidSize } from '../../../../utils/fluidSize'

const LogoImg = styled.img`
  width: ${fluidSize('160px')};
  color: white;
`

const Wrapper = styled.div`
  flex: 0 0 ${fluidSize('80px')};
  box-sizing: border-box;
  padding: ${fluidSize('24px')} 0;
  background: ${props => props.theme.colors.backgroundColor};
  text-align: center;
`

export { LogoImg, Wrapper }
