import { IMatch, IPlayer } from '../views/Matches/types'

const getUserIdsFromMatches = (matches: IMatch[]) => {
  const ids = []

  for (const match of matches) {
    for (const team of match.teams) {
      ids.push(...team.players.map((player: IPlayer) => player.user_id))
    }
  }

  return Array.from(new Set(ids.filter((id: string) => Boolean(id))))
}

export default getUserIdsFromMatches
export { getUserIdsFromMatches }
