import { Theme } from '../views/TenantDisplay/types'

export const theme: Theme = {
  colors: {
    backgroundColor: '#0E2433',
    lightColor: '#566670',
    darkColor: '#3E505C',
    textColor: '#ffffff',
    invertedTextColor: '#33302e',
    accentColor: '#ECFF00',
    timeslotBorderColor: '#aeb6b64d',
    matchTeamsBorderColor: 'rgba(0, 0, 0, 0.2)',
    goToCourtBackgroundColor: '#000000',
    goToCourtTextColor: '#A4D96C',
    uploadBackgroundColor: '#FFEECB',
    uploadTextColor: '#E6AA29',
  },
  radius: '8px',
  radius2x: '16px',
  scale: 1,
}
