import { TIMESLOT_GROUP_HEIGHT } from '../config/constants'

export const pixelInterpolation = (pixels: number) => {
  const height = window.innerHeight

  if (!height) {
    return pixels
  }

  return (pixels * height) / 1080
}

export const getPixelsByTime = (minutes: number) => minutes * (pixelInterpolation(TIMESLOT_GROUP_HEIGHT) / 60)
