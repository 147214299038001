import styled from 'styled-components'
import { fluidSize } from '../../../../utils'

const Picture = styled.img<{ anonymous?: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: ${props => (props.anonymous ? '#c3c3c3' : 'transparent')};
`

const Wrapper = styled.div<{ background?: string; size?: string }>`
  --size: ${props => fluidSize(`${props.size || 46}px`, props.theme.scale)};
  border-radius: 50%;
  min-width: var(--size);
  width: var(--size);
  height: var(--size);
  line-height: var(--size);
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  overflow: hidden;
  font-size: ${props => fluidSize('18px', props.theme.scale)};
  font-weight: bold;
  background: ${props => props.background ?? props.theme.colors.darkColor};
  color: ${props => props.theme.colors.textColor};
`

export { Picture, Wrapper }
