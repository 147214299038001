import { Logo as BaseLogo } from '@playtomic/display/views/TenantDisplay/components'
import styled from 'styled-components'
import { Header as BaseHeader } from '../Header'
import { PlayersList as BasePlayersList } from '../PlayersList'

const Header = styled(BaseHeader)``
const Logo = styled(BaseLogo)``
const PlayersList = styled(BasePlayersList)``

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.darkColor};
  height: 100%;
  display: flex;
  flex-direction: column;
`

export { Header, Logo, PlayersList, Wrapper }
