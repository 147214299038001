import React from 'react'
import { StyledProps } from '../../types'
import { SportId } from '../../../Matches/types'
import { Top10Players } from '../../../Top10'
import { Matches } from '../../../Matches'
import * as S from './Layout.styles'

interface LayoutProps extends StyledProps {
  tenantId: string
  fromStartDate: string
  toStartDate: string
  sportId: SportId | null
}

function Layout({ className, tenantId, fromStartDate, toStartDate, sportId }: LayoutProps) {
  return (
    <S.Wrapper className={className}>
      <S.Column>
        <Matches
          tenantId={tenantId}
          fromStartDate={fromStartDate}
          toStartDate={toStartDate}
          sportId={sportId}
        />
      </S.Column>
      <S.Column flex="216px">
        <Top10Players tenantId={tenantId} fromStartDate={fromStartDate} sportId={sportId} />
      </S.Column>
    </S.Wrapper>
  )
}

export default Layout
export { Layout }
