import styled from 'styled-components'

import { StyledProps } from '../../../TenantDisplay/types'
import { fluidSize } from '../../../../utils/fluidSize'

const Wrapper = styled.div`
  position: absolute;
  width: calc(100% - 3px);
  margin: 2px;
  border-radius: ${props => props.theme.radius};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.03);
  background-color: #f1f1f1;
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 35px,
    rgba(255, 255, 255, 0.5) 35px,
    rgba(255, 255, 255, 0.5) 70px
  );
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  @media (min-width: 3590px) {
    border-radius: ${props => props.theme.radius2x};
    margin: 4px;
    width: calc(100% - 6px);
  }
`

const Teams = styled.div<StyledProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 85%;
  min-width: auto;
  min-height: ${props => fluidSize('200px', props.theme.scale)};
  max-height: 400px;
  background: #ffffff;
  border-radius: ${props => props.theme.radius};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.02);
  z-index: 2;

  @media (max-width: 1919px) {
    min-width: auto;
  }
`

const TeamsUploadScore = styled.div<StyledProps>`
  position: absolute;
  background: ${props => props.theme.colors.uploadBackgroundColor};
  color: ${props => props.theme.colors.uploadTextColor};
  font-size: ${props => fluidSize('12px', props.theme.scale)};
  font-weight: bold;
  line-height: ${props => fluidSize('16px', props.theme.scale)};
  text-transform: uppercase;
  text-align: center;
  --height: ${props => fluidSize('34px', props.theme.scale)};
  width: 50%;
  height: var(--height);
  box-sizing: border-box;
  right: 0;
  top: calc(50% - var(--height) / 2);
  padding: ${props => fluidSize('9px', props.theme.scale)} 0;
`

const MatchStatusOverlay = styled.div<StyledProps & { opacity: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #000;
  border-radius: ${props => props.theme.radius};
  opacity: ${props => props.opacity};
`

const GoToCourt = styled.div<StyledProps>`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  --height: ${props => fluidSize('27px', props.theme.scale)}
  top: calc(50% - var(--height) / 2);
  z-index: 3;
  padding: ${props => fluidSize('4px', props.theme.scale)} 0;
  background: ${props => props.theme.colors.goToCourtBackgroundColor};

  color: ${props => props.theme.colors.goToCourtTextColor};
  text-transform: uppercase;
  text-align: center;
  font-size: ${props => fluidSize('16px', props.theme.scale)};
  font-weight: bold;
  letter-spacing: ${props => fluidSize('5px', props.theme.scale)};
`

const TennisBall = styled.div<StyledProps>`
  --height: ${props => fluidSize('33px', props.theme.scale)}
  position: absolute;
  top: calc(var(--top) - var(--height) / 2);
  left: ${props => fluidSize('-8px', props.theme.scale)};
  width: ${props => fluidSize('32px', props.theme.scale)};
  height: var(--height);
  line-height: ${props => fluidSize('32px', props.theme.scale)}
  text-align: center;
  background: ${props => props.theme.colors.accentColor};
  border-radius: 50%;
  z-index: 15;
  transition: all 150ms;
`

const TennisBallImg = styled.img<StyledProps>`
  --size: ${props => fluidSize('16px', props.theme.scale)}
  width: var(--size);
  height: var(--size);
  vertical-align: middle;
`

export { GoToCourt, MatchStatusOverlay, Teams, TeamsUploadScore, TennisBall, TennisBallImg, Wrapper }
