import styled from 'styled-components'

import { StyledProps } from '../../../TenantDisplay/types'
import { fluidSize } from '../../../../utils/fluidSize'

interface IPlayerName extends StyledProps {
  won: boolean
  isFinished: boolean
  visiblePadelsCount: number
}

export const PlayerName = styled.div<IPlayerName>`
  font-size: ${props => fluidSize('12px', props.theme.scale)};
  font-weight: ${props => (props.won ? 'bold' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: ${props => fluidSize(props.isFinished ? '14px' : '12px', props.theme.scale)};
  letter-spacing: normal;
  --width: ${props => fluidSize(props.visiblePadelsCount === 4 ? '75px' : '55px', props.theme.scale)};
  max-width: var(--width);
  width: var(--width);
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.invertedTextColor};
`

export const PlayerLevel = styled.div<StyledProps>`
  font-size: ${props => fluidSize('12px', props.theme.scale)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: ${props => fluidSize('14px', props.theme.scale)};
  letter-spacing: normal;
  color: ${props => props.theme.colors.backgroundColor};
  opacity: 0.4;
`

export const PlayerInfo = styled.div<{ isFinished: boolean }>`
  margin-left: ${props => (props.isFinished ? '0' : fluidSize('8px', props.theme.scale))};
  width: ${props => (props.isFinished ? '100%' : 'calc(100% - 46px)')};
`
