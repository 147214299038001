import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchTop10Players } from '../../store/thunks'
import { getTop10Players, isTop10PlayersLoading, isUsersLoading } from '../../../../store/selectors'
import { StyledProps } from '../../../TenantDisplay/types'
import { DATA_RELOAD_INTERVAL } from '../../../../config/constants'
import { SportId } from '../../../Matches/types'
import { useInterval, useThunkDispatch } from '../../../../hooks'
import * as S from './Top10Players.styles'

interface Top10PlayersProps extends StyledProps {
  tenantId: string
  fromStartDate: string
  sportId: SportId | null
}

function Top10Players({ className, tenantId, fromStartDate, sportId }: Top10PlayersProps) {
  const dispatch = useThunkDispatch()

  const playersLoading = useSelector(isTop10PlayersLoading)
  const usersLoading = useSelector(isUsersLoading)
  const playersList = useSelector(getTop10Players)

  const fetchTop10 = useCallback(() => dispatch(fetchTop10Players(tenantId, fromStartDate, sportId)), [
    dispatch,
    tenantId,
    fromStartDate,
    sportId,
  ])

  useInterval(() => fetchTop10(), DATA_RELOAD_INTERVAL)
  useEffect(() => {
    fetchTop10()
  }, [fetchTop10])

  return (
    <S.Wrapper className={className}>
      <S.Header />
      <S.PlayersList isPlayersLoading={playersLoading} isUsersLoading={usersLoading} players={playersList} />
      <S.Logo />
    </S.Wrapper>
  )
}

export default Top10Players
export { Top10Players }
