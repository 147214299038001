import styled from 'styled-components'
import { TIMESLOT_GROUP_HEIGHT, TIMESLOT_LABEL_MARGIN } from '../../../../config/constants'
import { StyledProps } from '../../../TenantDisplay/types'
import { fluidSize } from '../../../../utils/fluidSize'

const TimeslotGroup = styled.div`
  box-sizing: border-box;
  height: ${fluidSize(`${TIMESLOT_GROUP_HEIGHT}px`)};
  display: flex;
  flex-direction: column;
`

const Timeslot = styled.div`
  flex: 1;
  padding: ${fluidSize('8px')};
`

const Timelabel = styled.div<StyledProps & { opacity?: number }>`
  margin-top: ${fluidSize(`${TIMESLOT_LABEL_MARGIN}px`)};
  font-size: ${fluidSize('21px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: ${fluidSize('32px')};
  letter-spacing: normal;
  text-align: right;
  color: ${props => props.theme.colors.textColor};
  opacity: ${props => props.opacity || 1};
`

export { Timelabel, Timeslot, TimeslotGroup }
