import React, { useContext, useEffect, useRef, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import moment from 'moment'
import { StyledProps } from '../../../TenantDisplay/types'
import { IMatch } from '../../types'
import {
  PADELS_COLUMN_COUNT,
  PADELS_ROTATE_INTERVAL,
  TIMESLOT_LABEL_MARGIN,
  TIMESLOTS_START_TIME,
  TIMESLOTS_VISIBLE_RANGE_FROM,
} from '../../../../config/constants'
import { getPixelsByTime, pixelInterpolation } from '../../../../utils/timeslots'
import { MatchesContext } from '../Matches'
import { theme } from '../../../../config/theme'
import { useInterval, useSearchParams, useWindowSize } from '../../../../hooks'
import { IResource } from '@playtomic/display/views/Tenant/interfaces'
import * as S from './MatchesGrid.styles'

interface MatchesGridProps extends StyledProps {
  resources: IResource[]
  resourcesMatches: Record<string, IMatch[]>
}

function MatchesGrid({ className, resources, resourcesMatches }: MatchesGridProps) {
  const [currentVisiblePadelsGroup, setCurrentVisiblePadelsGroup] = useState(0)
  const [nowTopPosition, setNowTopPosition] = useState(0)
  const { currentTime } = useContext(MatchesContext)
  const searchParams = useSearchParams()
  const padelsRotateInterval = searchParams.has('refresh_time')
    ? Number(searchParams.get('refresh_time')) * 1000
    : PADELS_ROTATE_INTERVAL
  const padelsColumnCount = searchParams.has('court_number')
    ? Number(searchParams.get('court_number'))
    : PADELS_COLUMN_COUNT

  const timeGridRef = useRef<HTMLDivElement>(null)
  const windowSize = useWindowSize()

  useInterval(() => {
    const maxGroups = Math.ceil(resources.length / padelsColumnCount)

    setCurrentVisiblePadelsGroup(currentGroup => (currentGroup >= maxGroups - 1 ? 0 : currentGroup + 1))
  }, padelsRotateInterval)

  useEffect(() => {
    const diffCurrentTimeWithStartTime = moment(currentTime, 'HH:mm').diff(
      moment(TIMESLOTS_START_TIME, 'HH:mm'),
      'minutes',
    )

    const scrollHeight = getPixelsByTime(diffCurrentTimeWithStartTime + TIMESLOTS_VISIBLE_RANGE_FROM)
    const gridPaddingTop = pixelInterpolation(62)

    timeGridRef.current?.scrollTo({ top: scrollHeight })
    setNowTopPosition(
      (getPixelsByTime(diffCurrentTimeWithStartTime) || 0) +
        gridPaddingTop +
        pixelInterpolation(TIMESLOT_LABEL_MARGIN) +
        pixelInterpolation(8),
    )
  }, [currentTime, windowSize])

  const visiblePadels = new Array(4).fill('')
  const currentPadels = [...resources].splice(
    currentVisiblePadelsGroup * padelsColumnCount,
    padelsColumnCount,
  )

  visiblePadels.splice(0, currentPadels.length, ...currentPadels)

  return (
    <S.Wrapper className={className}>
      <S.TenantResources>
        {visiblePadels.map((resource, index) => (
          <S.TenantResource key={`${resource.resource_id}-${index}`}>{resource.name}</S.TenantResource>
        ))}
      </S.TenantResources>
      <ThemeProvider
        theme={{
          ...theme,
          scale: Math.min(1, 5 / visiblePadels.length),
        }}>
        <S.TimeGrid ref={timeGridRef}>
          <S.Now style={{ top: nowTopPosition }} />
          <S.TimeColumn>
            <S.Timeslots showLabel={true} showStepLabel={true} />
          </S.TimeColumn>
          {visiblePadels.map((resource, index) => (
            <S.DayColumn key={`${resource.resource_id}-${index}`}>
              <S.Timeslots showLabel={false} />
              <S.Matches>
                {resourcesMatches[resource.resource_id]?.map((match: IMatch) => (
                  <S.Match key={match.match_id} match={match} visiblePadelsCount={visiblePadels.length} />
                ))}
              </S.Matches>
            </S.DayColumn>
          ))}
        </S.TimeGrid>
      </ThemeProvider>
    </S.Wrapper>
  )
}

export default MatchesGrid
export { MatchesGrid }
