import styled from 'styled-components'
import { StyledProps } from '../../../TenantDisplay/types'
import { fluidSize } from '../../../../utils/fluidSize'

const TeamPlayers = styled.div<StyledProps>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-height: ${props => fluidSize('100px', props.theme.scale)};
`

interface TeamDividerProps extends StyledProps {
  isFinished?: boolean
}

const TeamDivider = styled.div<TeamDividerProps>`
  position: absolute;
  width: ${props => (props.isFinished ? '20%' : '100%')};
  bottom: 0;
  left: ${props => (props.isFinished ? '40%' : '0')};
  height: 1px;
  background: ${props => props.theme.colors.matchTeamsBorderColor};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 0 ${props => fluidSize('19px', props.theme.scale)};
  align-items: center;
  min-height: ${props => fluidSize('100px', props.theme.scale)};
`

export { TeamDivider, TeamPlayers, Wrapper }
