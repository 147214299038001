import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware, { ThunkDispatch as ThunkDispatchBase } from 'redux-thunk'
import { createLogger } from 'redux-logger'

import { IActions } from './actions'
import * as reducers from './reducers'

const reducer = combineReducers(reducers)

export type StoreState = ReturnType<typeof reducer>
export type ThunkDispatch = ThunkDispatchBase<StoreState, unknown, IActions>

const loggerMiddleware = createLogger()

const store = createStore<StoreState, IActions, unknown, unknown>(
  reducer,
  applyMiddleware(thunkMiddleware, loggerMiddleware),
)

export default store
