import { useDispatch } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import { IActions } from '../store/actions'
import { StoreState } from '../store'

type Dispatch = ThunkDispatch<StoreState, unknown, IActions>

const useThunkDispatch = () => useDispatch<Dispatch>()

export { useThunkDispatch }
