import React from 'react'
import { StyledProps } from '../../../TenantDisplay/types'
import { IUser } from '../../../Users/interfaces'
import { UserPicture } from '../../../Users/components/UserPicture'
import * as S from './PlayersListItem.styles'

interface PlayersListItemProps extends StyledProps {
  player: IUser
  position: number
}

function PlayersListItem({ className, player, position }: PlayersListItemProps) {
  if (!player) {
    return null
  }

  return (
    <S.Wrapper className={className}>
      <S.PlayerPosition>{position}</S.PlayerPosition>
      <UserPicture picture={player.picture} name={player.full_name} />
      <S.PlayerName>{player.full_name}</S.PlayerName>
    </S.Wrapper>
  )
}

export default PlayersListItem
export { PlayersListItem }
