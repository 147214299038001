import styled from 'styled-components'

import { StyledProps } from '../../../TenantDisplay/types'
import { fluidSize } from '../../../../utils/fluidSize'

const CurrentTime = styled.div<StyledProps>`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 0 ${props => props.theme.radius} 0;
  background-color: ${props => props.theme.colors.accentColor};
  color: ${props => props.theme.colors.invertedTextColor};
  text-align: center;
  font-size: ${fluidSize('25px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: ${fluidSize('40px')};
  padding: ${fluidSize('8px')};
  width: ${fluidSize('96px')};
  box-sizing: border-box;

  @media (min-width: 3590px) {
    border-radius: 0 0 ${props => props.theme.radius2x} 0;
  }
`

const Wrapper = styled.div<StyledProps>`
  position: relative;
  background-color: ${props => props.theme.colors.lightColor};
  font-size: ${fluidSize('21px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: ${fluidSize('41px')};
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  padding: ${fluidSize('8px')};
  color: ${props => props.theme.colors.textColor};
`

export { CurrentTime, Wrapper }
