import styled from 'styled-components'
import { StyledProps } from '../../../TenantDisplay/types'
import { fluidSize } from '../../../../utils/fluidSize'

const NowLabel = styled.div<StyledProps>`
  flex: 0 0 ${fluidSize('70px')};
  border-radius: 0 ${props => props.theme.radius} ${props => props.theme.radius} 0;
  font-size: ${fluidSize('16px')};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: ${fluidSize('32px')};
  text-align: center;
  text-transform: uppercase;
  padding: ${fluidSize('2px')} ${fluidSize('7px')} 0 0;
  background: ${props => props.theme.colors.accentColor};
  color: ${props => props.theme.colors.invertedTextColor};

  @media (min-width: 3590px) {
    border-radius: 0 ${props => props.theme.radius2x} ${props => props.theme.radius2x} 0;
  }
`
const NowLine = styled.div<StyledProps>`
  flex: auto;
  height: 1px;
  background: ${props => props.theme.colors.accentColor};

  @media (min-width: 3590px) {
    height: 2px;
  }
`

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10;
`

export { NowLabel, NowLine, Wrapper }
