import { ActionType, getType } from 'typesafe-actions'

import * as actions from './actions'
import { ITenant } from '../interfaces'

export interface ITenantState {
  data: ITenant | null
  isLoading: boolean
  error?: string
}

export type TenantAction = ActionType<typeof actions>

const initialState: ITenantState = {
  data: null,
  isLoading: false,
}

const tenant = (state = initialState, action: TenantAction): ITenantState => {
  switch (action.type) {
    case getType(actions.fetchTenantPending):
      return {
        ...state,
        isLoading: true,
      }

    case getType(actions.fetchTenantSuccess):
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }

    case getType(actions.fetchTenantFailure):
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.payload,
      }

    default:
      return state
  }
}

export default tenant
