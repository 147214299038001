import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { fetchDayMatches } from '../../store/thunks'
import {
  getMatchesGroupedByTenantResourcesAndSport,
  getTenantResourcesBySport,
  getTenant,
  isMatchesLoading,
  isTenantLoading,
} from '../../../../store/selectors'
import { CURRENT_TIME_INTERVAL, DATA_RELOAD_INTERVAL } from '../../../../config/constants'
import { fetchTenant } from '../../../Tenant/store/thunks'
import { SportId } from '../../types'
import { useInterval, useThunkDispatch } from '../../../../hooks'
import { StyledProps } from '@playtomic/display/utils/types'
import * as S from './Matches.styles'

interface MatchesProps extends StyledProps {
  tenantId: string
  fromStartDate: string
  toStartDate: string
  sportId: SportId | null
}

interface IMatchesContext {
  currentTime: string
  timezone: string
}

export const MatchesContext = createContext<IMatchesContext>({
  currentTime: '',
  timezone: 'Europe/London',
})

function Matches({ className, tenantId, fromStartDate, toStartDate, sportId }: MatchesProps) {
  const dispatch = useThunkDispatch()
  const isLoading = useSelector(isMatchesLoading)
  const tenant = useSelector(getTenant)
  const tenantLoading = useSelector(isTenantLoading)
  const matchesGroupedByTenantResource = useSelector(getMatchesGroupedByTenantResourcesAndSport(sportId))
  const tenantResources = useSelector(getTenantResourcesBySport(sportId))
  const [currentTime, setCurrentTime] = useState('')
  const [timezone, setTimezone] = useState('Europe/London')

  const fetchMatches = useCallback(
    () => dispatch(fetchDayMatches(tenantId, fromStartDate, toStartDate, sportId)),
    [dispatch, tenantId, fromStartDate, toStartDate, sportId],
  )
  const fetchTenantInformation = useCallback(() => dispatch(fetchTenant(tenantId)), [dispatch, tenantId])
  const setTime = () => setCurrentTime(moment().format('HH:mm'))

  useInterval(() => setTime(), CURRENT_TIME_INTERVAL)
  useInterval(() => fetchMatches(), DATA_RELOAD_INTERVAL)

  useEffect(() => {
    fetchMatches()
    setTime()
  }, [fetchMatches])

  useEffect(() => {
    fetchTenantInformation()
  }, [fetchTenantInformation])

  useEffect(() => {
    setTimezone(tenant ? tenant.address.timezone : 'Europe/London')
  }, [tenant])

  return (
    <S.Wrapper className={className}>
      <Helmet title={`Playtomic Display | ${tenant?.tenant_name}`} />
      <S.Spinner loading={isLoading || tenantLoading}>
        <MatchesContext.Provider value={{ currentTime, timezone }}>
          {tenant && <S.Header tenant={tenant} />}
          <S.MatchesGrid resources={tenantResources} resourcesMatches={matchesGroupedByTenantResource} />
        </MatchesContext.Provider>
      </S.Spinner>
    </S.Wrapper>
  )
}

export default Matches
export { Matches }
