import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import axios from 'axios'
import to from 'await-to-js'
import { normalize } from 'normalizr'

import { IUsersAction } from './reducers'
import { fetchUsersFailure, fetchUsersPending, fetchUsersSuccess } from './actions'
import { endpoint } from '../../../config/api'
import { IUser } from '../interfaces'
import { usersSchema } from '../schemas'
import { StoreState } from '../../../store'

export const fetchUsers = (
  userIds: string[],
): ThunkAction<Promise<string[] | undefined>, StoreState, unknown, IUsersAction> => async (
  dispatch: ThunkDispatch<StoreState, unknown, IUsersAction>,
) => {
  dispatch(fetchUsersPending())

  const [err, response] = await to(
    axios.get<IUser[]>(`${endpoint}/v2/users`, { params: { user_id: userIds.join(',') } }),
  )

  if (response) {
    const normalizedData = normalize(response.data, usersSchema)

    dispatch(fetchUsersSuccess(normalizedData.entities.users))

    return normalizedData.result
  }

  if (err) {
    dispatch(fetchUsersFailure(err.message))
  }

  return undefined
}
