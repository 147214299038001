import React from 'react'
import styled from 'styled-components'

import { UserPicture } from '../../../Users/components/UserPicture'

import { StyledProps } from '../../../TenantDisplay/types'
import { IPlayer } from '../../types'
import { useSelector } from 'react-redux'
import { getUsersByIds } from '../../../../store/selectors'
import { PlayerInfo, PlayerName, PlayerLevel } from './styles'
import { fluidSize } from '../../../../utils/fluidSize'

interface IProps extends StyledProps {
  player: IPlayer | null
  isFinished: boolean
  won: boolean
  visiblePadelsCount: number
  hideGuests: boolean
}

const TeamPlayer: React.FC<IProps> = ({ className, player, isFinished, won, visiblePadelsCount }) => {
  const anonymous = !player

  const firstName = player ? player.name.split(' ').shift() : 'Guest'
  const playerName = player ? player.name : 'Guest'
  const users = useSelector(getUsersByIds)
  const picture = player && player.user_id ? users[player.user_id]?.picture : null
  const pictureSize = isFinished ? 46 : 60

  return (
    <div className={className}>
      <UserPicture
        anonymous={anonymous}
        picture={picture}
        name={player ? player.name : ''}
        size={pictureSize}
      />
      <PlayerInfo isFinished={isFinished}>
        <PlayerName isFinished={isFinished} won={won} visiblePadelsCount={visiblePadelsCount}>
          {isFinished ? firstName : playerName}
        </PlayerName>
        <PlayerLevel>{player?.level_value}</PlayerLevel>
      </PlayerInfo>
    </div>
  )
}

export default styled(TeamPlayer)`
  text-align: ${props => (props.isFinished ? 'center' : 'left')};
  flex: 1;
  max-width: 50%;
  min-height: ${props => fluidSize('74px', props.theme.scale)};
  display: flex;
  flex-direction: ${props => (props.isFinished ? 'column' : 'row')};
  align-items: ${props => (props.isFinished ? 'center' : 'center')};
  justify-content: ${props => (props.isFinished ? 'flex-start' : 'start')};
  margin-bottom: ${props => (props.hideGuests ? '-90px' : '0')};
`
