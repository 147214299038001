export const pictureColours = [
  '#017069',
  '#A4D96C',
  '#50E3C2',
  '#CDEAAE',
  '#FACA64',
  '#50C1E3',
  '#2F4E8B',
  '#EE3663',
  '#BD10E0',
  '#9013FE',
]
