import React, { useMemo } from 'react'
import moment from 'moment'
import { TIMESLOT_STEP, TIMESLOTS_END_TIME, TIMESLOTS_START_TIME } from '../../../../config/constants'
import * as S from './Timeslots.styles'

interface SlotGroup {
  label: string
  slots: Slot[]
}

interface Slot {
  label: string
}

interface TimeslotsProps {
  showLabel: boolean
  showStepLabel?: boolean
}

function Timeslots({ showLabel, showStepLabel }: TimeslotsProps) {
  const timeslots: SlotGroup[] = useMemo(() => {
    const slotGroups: SlotGroup[] = []
    const currentTime = moment(TIMESLOTS_START_TIME, 'HH:mm')
    const endTime = moment(TIMESLOTS_END_TIME, 'HH:mm')

    while (currentTime.isBefore(endTime)) {
      const group: SlotGroup = {
        label: currentTime.format('HH:mm'),
        slots: [{ label: '' }],
      }

      currentTime.add(TIMESLOT_STEP, 'minutes')

      const nextHour = currentTime.clone().add(1, 'hour').minute(0)

      while (currentTime.isBefore(nextHour)) {
        group.slots.push({
          label: currentTime.format(':mm'),
        })

        currentTime.add(TIMESLOT_STEP, 'minutes')
      }

      slotGroups.push(group)
    }

    return slotGroups
  }, [])

  return (
    <>
      {timeslots.map(group => (
        <S.TimeslotGroup className="timeslot-group" key={group.label}>
          {group.slots.map((slot, index) => (
            <S.Timeslot key={slot.label}>
              {index === 0 && showLabel && <S.Timelabel>{group.label}</S.Timelabel>}
              {index > 0 && showStepLabel && <S.Timelabel opacity={0.5}>{slot.label}</S.Timelabel>}
            </S.Timeslot>
          ))}
        </S.TimeslotGroup>
      ))}
    </>
  )
}

export default Timeslots
export { Timeslots }
