import styled from 'styled-components'
import { Spinner as BaseSpinner } from '@playtomic/display/views/TenantDisplay/components'
import { PlayersListItem as BasePlayersListItem } from '../PlayersListItem'

const PlayersListItem = styled(BasePlayersListItem)``
const Spinner = styled(BaseSpinner)``
const Wrapper = styled.div`
  position: relative;
  flex: 1;
  overflow: overlay;
  overflow: scroll;
`

export { PlayersListItem, Spinner, Wrapper }
