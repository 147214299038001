import to from 'await-to-js'
import axios from 'axios'

import { ThunkDispatch } from '../../../store'
import { fetchTenantPending, fetchTenantSuccess, fetchTenantFailure } from './actions'
import { endpoint } from '../../../config/api'
import { ITenant } from '../interfaces'

export const fetchTenant = (tenantId: string) => async (dispatch: ThunkDispatch) => {
  dispatch(fetchTenantPending())

  const [err, response] = await to(
    axios.get<ITenant[]>(`${endpoint}/v1/tenants`, { params: { tenant_id: tenantId } }),
  )

  if (response) {
    dispatch(fetchTenantSuccess(response.data[0]))
  }

  if (err) {
    dispatch(fetchTenantFailure(err.message))
  }

  return undefined
}
