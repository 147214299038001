import React, { HTMLAttributes } from 'react'
import { StyledProps } from '../../../TenantDisplay/types'
import * as S from './Now.styles'

type NowProps = StyledProps & HTMLAttributes<HTMLDivElement>

function Now({ className, ...other }: NowProps) {
  return (
    <S.Wrapper className={className} {...other}>
      <S.NowLabel>Now</S.NowLabel>
      <S.NowLine />
    </S.Wrapper>
  )
}

export default Now
export { Now }
